import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    loginUser: (state) => state.loginUser,
  }),
  ...mapGetters('auth', ['loggedIn', 'email', 'isAdmin']),
}

export const authMethods = mapActions('auth', [
  'logIn',
  'logOut',
  'saveLoginUserDetails',
  'newPasswordChallenge',
  'validate',
])
// ])

export const userComputed = {
  ...mapGetters('users', ['firstName', 'lastName', 'phoneNumber', 'id']),
  ...mapGetters('auth', ['email', 'isAdmin', 'roles', 'id']),
}

export const messagesComputed = mapGetters('messages', ['messages'])

export const messagesMethods = mapActions('messages', ['setNewMessage'])

export const onlineComputed = mapGetters('online', ['isOnline'])

export const onlineMethods = mapActions('online', ['setOnlineStatus'])
