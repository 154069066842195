<script>
import { authComputed, onlineComputed } from '@state/helpers'
import NavBar from '@components/nav-bar'
import AppFooter from '@components/app-footer'
import AppNotify from '@components/app-notify'
import LoadingIndicator from '@components/LoadingIndicator'
import AppOnline from '@components/app-online'
import BottomNavigation from '@components/bottom-navigation'
export default {
  components: {
    NavBar,
    AppFooter,
    AppNotify,
    BottomNavigation,
    AppOnline,
    LoadingIndicator,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    // ...authLinks,
    ...authComputed,
    ...onlineComputed,
  },
}
</script>

<template>
  <v-app>
    <NavBar :drawer.sync="drawer" />
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-items>
        <v-btn
          to="/"
          color="white"
          small
          tag="v-toolbar-title"
          active-class
          text
          >Home</v-btn
        >
        <v-btn
          to="/dashboard"
          small
          color="white"
          tag="v-toolbar-title"
          active-class
          text
          >Dashboard</v-btn
        >
      </v-toolbar-items>
      <v-spacer />
      <v-icon v-if="!isOnline" medium color="error">$signalOff</v-icon>
      <v-toolbar-items>
        <v-btn v-if="loggedIn" :to="{ name: 'logout' }" text>Log out</v-btn>
        <v-btn v-else :to="{ name: 'login' }" text>Log in</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <LoadingIndicator />
      <v-container fluid>
        <AppNotify />
        <slot />
      </v-container>
    </v-main>
    <AppFooter
      :links="[{ name: 'home' }, { name: 'profile' }, { name: 'terms' }]"
    />
    <BottomNavigation />
    <AppOnline />
  </v-app>
</template>
