import Auth from '@aws-amplify/auth'
import { link } from '@src/graphql/http-link'

// const logger = new Logger('Auth Store')
export const state = {
  currentUser: getSavedState('auth.currentUser'),
  loginUser: () => {
    let user = getSavedState('auth.loginUser')
    return user
  },
  userData: () => {
    let userData = {}
    try {
      userData = getSavedState('auth.userData')
      return userData
    } catch {
      userData = {
        roles: ['CLIENT'],
      }
      return userData
    }
  },
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
    // setDefaultAuthHeaders(state)
  },
  SET_LOGIN_USER(state, newValue) {
    state.loginUser = newValue
    saveState('auth.loginUser', newValue)
    // setDefaultAuthHeaders(state)
  },
  SET_USER_DATA(state, newValue) {
    state.userData = newValue
    saveState('auth.userData', newValue)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  email(state) {
    if (
      state.currentUser &&
      state.currentUser.idToken &&
      state.currentUser.idToken.payload
    ) {
      return state.currentUser.idToken.payload.email
    }
    return ''
  },
  isAdmin(state) {
    return (
      state.currentUser &&
      state.currentUser.idToken &&
      state.currentUser.idToken.payload &&
      state.currentUser.idToken.payload['cognito:groups'] &&
      state.currentUser.idToken.payload['cognito:groups'].some(
        (group) => group === 'admin'
      )
    )
  },
  roles(state) {
    if (state.userData && state.userData.roles) {
      return state.userData.roles
    }
  },
  id(state) {
    if (state.userData && state.userData.id) {
      return state.userData.id
    }
  },
  accountLocations(state) {
    if (state.userData && state.userData.roles) {
      return state.userData.accountLocations
    }
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    // setDefaultAuthHeaders(state)
    dispatch('validate')
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  },

  async saveLoginUserDetails({ commit }, { user } = {}) {
    commit('SET_LOGIN_USER', user)
  },
  getCredentials({ commit, dispatch, getters }) {
    if (window.PasswordCredential) {
      navigator.credentials.get({
        password: true,
        mediation: 'silent',
      })
    }
  },
  async logIn({ commit, dispatch, getters }, { email, password } = {}) {
    try {
      if (getters.loggedIn) {
        const user = dispatch('validate')
        return user
      }
      const user = await Auth.signIn(email, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return user
      }
      commit('SET_CURRENT_USER', user.getSignInUserSession())
      if (user.getSignInUserSession() != null) {
        const userData = await getMe()
        commit('SET_USER_DATA', userData)
      } else {
        const userData = {
          roles: ['CLIENT'],
        }
        commit('SET_USER_DATA', userData)
      }
      return user
    } catch (err) {
      throw err
    }
  },
  async forgotPassword(_, { email }) {
    try {
      const data = await Auth.forgotPassword(email)
      return data
    } catch (err) {
      throw err
    }
  },
  async submitForgotPassword(_, { email, code, newPassword }) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
      return true
    } catch (err) {
      throw err
    }
  },
  async changePassword(_, { oldPassword, newPassword }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const data = await Auth.changePassword(user, oldPassword, newPassword)
      return data
    } catch (err) {
      throw err
    }
  },
  async newPasswordChallenge(
    { commit, dispatch },
    { password, user, attributes } = {}
  ) {
    if (password === null || user === null || attributes === null) {
      throw new Error('Must complete all the entries.')
    }
    try {
      Auth.completeNewPassword(user, password, attributes).then((u) => {
        commit('SET_CURRENT_USER', u.getSignInUserSession())
        commit('SET_LOGIN_USER', null)
        return u
      })
    } catch (err) {
      commit('SET_LOGIN_USER', null)
      dispatch('errors/setErrorMessage', err.message)
      throw err
    }
  },

  // Logs out the current user.
  async logOut({ commit }) {
    try {
      await Auth.signOut()
    } finally {
      commit('SET_CURRENT_USER', null)
      commit('SET_LOGIN_USER', null)
      commit('SET_USER_DATA', null)
    }
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // validate({ commit, state }) {
  async validate({ commit, state }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user.getSignInUserSession() != null) {
        commit('SET_CURRENT_USER', user.getSignInUserSession())
        const userData = await getMe()
        commit('SET_USER_DATA', userData)
        return user
      }
      const data = await new Promise((resolve, reject) => {
        user.getSession(function(err, data) {
          if (err) {
            reject(err)
          }
          resolve(user)
        })
      })
      commit('SET_CURRENT_USER', data)
      const userData = await getMe()
      commit('SET_USER_DATA', userData)
      return user
    } catch (err) {
      const credentials = await Auth.currentCredentials()
      if (credentials.statusCode === 400) {
        commit('SET_CURRENT_USER', null)
        throw err
      }
      try {
        const user = await Auth.currentAuthenticatedUser()
        commit('SET_CURRENT_USER', user.getSignInUserSession())
        const userData = await getMe()
        commit('SET_USER_DATA', userData)
        return user
      } catch (err) {
        commit('SET_CURRENT_USER', null)
        throw err
      }
    }

    // Auth.userSession(unValidatedUser)
    // return new Promise(function(resolve, reject) {
    //   Auth.currentAuthenticatedUser()
    //     .then((user) => {
    //       // console.debug({message: 'validated', user: JSON.parse(JSON.stringify(user))})
    //       if (user.getSignInUserSession() != null) {
    //         commit('SET_CURRENT_USER', user.getSignInUserSession())
    //         resolve(user)
    //       }
    //       user.getSession(function(err, data) {
    //         if (err) {
    //           // console.debug({message: "error getting session", error: err})
    //           reject(err)
    //         }
    //         commit('SET_CURRENT_USER', data)
    //         resolve(user)
    //       })
    //     })
    //     .catch((err) => {
    //       reject(err)
    //     })
    // }).catch(() => {
    //   // let stateDup = JSON.parse(JSON.stringify(state))
    //   // console.debug({message: 'error validating', error: err, state: stateDup})
    //   return Auth.currentCredentials()
    //     .then((credentials) => {
    //       // console.debug({message: 'credentials found', creds: credentials})
    //       if (credentials.needsRefresh()) {
    //         credentials.refresh(state.currentUser.refreshToken, (ret) => {
    //           // console.debug({message: 'refreshing access token', returnInfo: ret})
    //           return Auth.currentAuthenticatedUser().then((user) => {
    //             // console.debug({message: 'found user after fetched credentials', user: JSON.parse(JSON.stringify(user))})
    //             commit('SET_CURRENT_USER', user.getSignInUserSession())
    //             return user
    //           })
    //         })
    //       }
    //       return Auth.currentAuthenticatedUser().then((user) => {
    //         // console.debug({message: 'found user after fetched credentials', user: JSON.parse(JSON.stringify(user))})
    //         commit('SET_CURRENT_USER', user.getSignInUserSession())
    //         return user
    //       })
    //     })
    //     .catch(() => {
    //       // console.debug({message: 'refreshing error informaton', error: err})
    //       commit('SET_CURRENT_USER', null)
    //       return false
    //     })
    // })
    // return axios
    //   .get('/api/session')
    //   .then(response => {
    //     const user = response.data
    //     commit('SET_CURRENT_USER', user)
    //     return user
    //   })
    //   .catch(error => {
    //     if (error.response && error.response.status === 401) {
    //       commit('SET_CURRENT_USER', null)
    //     }
    //     return null
    //   })

    // Auth.userSession(unValidatedUser)
    // return new Promise(function(resolve, reject) {
    //   Auth.currentAuthenticatedUser()
    //     .then((user) => {
    //       // console.debug({message: 'validated', user: JSON.parse(JSON.stringify(user))})
    //       if (user.getSignInUserSession() != null) {
    //         commit('SET_CURRENT_USER', user.getSignInUserSession())
    //         resolve(user)
    //       }
    //       user.getSession(function(err, data) {
    //         if (err) {
    //           // console.debug({message: "error getting session", error: err})
    //           reject(err)
    //         }
    //         commit('SET_CURRENT_USER', data)
    //         resolve(user)
    //       })
    //     })
    //     .catch((err) => {
    //       reject(err)
    //     })
    // }).catch(() => {
    //   // let stateDup = JSON.parse(JSON.stringify(state))
    //   // console.debug({message: 'error validating', error: err, state: stateDup})
    //   return Auth.currentCredentials()
    //     .then((credentials) => {
    //       // console.debug({message: 'credentials found', creds: credentials})
    //       if (credentials.needsRefresh()) {
    //         credentials.refresh(state.currentUser.refreshToken, (ret) => {
    //           // console.debug({message: 'refreshing access token', returnInfo: ret})
    //           return Auth.currentAuthenticatedUser().then((user) => {
    //             // console.debug({message: 'found user after fetched credentials', user: JSON.parse(JSON.stringify(user))})
    //             commit('SET_CURRENT_USER', user.getSignInUserSession())
    //             return user
    //           })
    //         })
    //       }
    //       return Auth.currentAuthenticatedUser().then((user) => {
    //         // console.debug({message: 'found user after fetched credentials', user: JSON.parse(JSON.stringify(user))})
    //         commit('SET_CURRENT_USER', user.getSignInUserSession())
    //         return user
    //       })
    //     })
    //     .catch(() => {
    //       // console.debug({message: 'refreshing error informaton', error: err})
    //       commit('SET_CURRENT_USER', null)
    //       return false
    //     })
    // })
    // return axios
    //   .get('/api/session')
    //   .then(response => {
    //     const user = response.data
    //     commit('SET_CURRENT_USER', user)
    //     return user
    //   })
    //   .catch(error => {
    //     if (error.response && error.response.status === 401) {
    //       commit('SET_CURRENT_USER', null)
    //     }
    //     return null
    //   })
  },
}

// ===
// Private helpers
// ===

function getMe() {
  console.debug(state.currentUser)
  let res = fetch(link['bowvue_base_uri'] + '/me', {
    method: 'GET',
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + state.currentUser.accessToken.jwtToken,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((error) => console.debug('Error: ', error))
  return res
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

// function setDefaultAuthHeaders(state) {
//   // axios.defaults.headers.common.Authorization = state.currentUser
//   //   ? state.currentUser.jwtToken
//   //   : ''
// }
