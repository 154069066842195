import Vue from 'vue'
import Vuetify, {
  VCard,
  VCardText,
  VCardTitle,
  VSpacer,
  VCardActions,
} from 'vuetify/lib'
import {
  mdiChartBar,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiAccountKey,
  mdiAccountMinus,
  mdiAccountPlus,
  mdiAccountLock,
  mdiAccountRemove,
  mdiAccountSearch,
  mdiAccountSupervisor,
  mdiViewDashboard,
  mdiDownload,
  mdiDelete,
  mdiDevices,
  mdiCamera,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircleOutline,
  mdiCircleSlice2,
  mdiCircleSlice4,
  mdiCircleSlice6,
  mdiCircleSlice8,
  mdiFileDocument,
  mdiCloudDownload,
  mdiEmail,
  mdiEyeOff,
  mdiHome,
  mdiKey,
  mdiLockReset,
  mdiDomain,
  mdiLock,
  mdiLockAlert,
  mdiLockOpenVariant,
  mdiExitToApp,
  mdiMap,
  mdiFormTextboxPassword,
  mdiPhone,
  mdiPencilOutline,
  mdiMagnify,
  mdiEmailSend,
  mdiEye,
  mdiSignalOff,
  mdiTrashCanOutline,
  mdiWidgets,
  mdiToolbox,
  mdiEyeOutline,
  mdiFlash,
  mdiHammerWrench,
  mdiTicket,
  mdiCashMultiple,
  mdiReply,
  mdiCommentTextMultipleOutline,
  mdiPlus,
} from '@mdi/js'

Vue.use(Vuetify, {
  components: {
    VCard,
    VCardText,
    VCardTitle,
    VSpacer,
    VCardActions,
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      account: mdiAccountCircle,
      accountLock: mdiAccountLock,
      accountRemove: mdiAccountRemove,
      accountSearch: mdiAccountSearch,
      accountSupervisor: mdiAccountSupervisor,
      addContractor: mdiAccountPlus,
      addNew: mdiPlus,
      barChart: mdiChartBar,
      comment: mdiCommentTextMultipleOutline,
      dashboard: mdiViewDashboard,
      delete: mdiTrashCanOutline,
      devices: mdiDevices,
      document: mdiFileDocument,
      download: mdiCloudDownload,
      deleteDocument: mdiDelete,
      email: mdiEmail,
      expand: mdiChevronDown,
      edit: mdiPencilOutline,
      reduce: mdiChevronUp,
      grantAdmin: mdiAccountKey,
      arcFlash: mdiFlash,
      hide: mdiEyeOff,
      home: mdiHome,
      key: mdiKey,
      location: mdiDomain,
      lock: mdiLock,
      lockOpen: mdiLockOpenVariant,
      logout: mdiExitToApp,
      map: mdiMap,
      password: mdiFormTextboxPassword,
      pdfDownload: mdiDownload,
      phone: mdiPhone,
      photo: mdiCamera,
      preventativeMaintenance: mdiToolbox,
      passwordReset: mdiLockAlert,
      removeAdmin: mdiAccountMinus,
      reply: mdiReply,
      resetLocation: mdiLockReset,
      search: mdiMagnify,
      sendEmail: mdiEmailSend,
      show: mdiEye,
      signalOff: mdiSignalOff,
      statusZero: mdiCircleOutline,
      statusQuarter: mdiCircleSlice2,
      statusHalf: mdiCircleSlice4,
      statusThreeQuarter: mdiCircleSlice6,
      statusFull: mdiCircleSlice8,
      tools: mdiHammerWrench,
      users: mdiAccountGroup,
      visMechInspection: mdiEyeOutline,
      widgets: mdiWidgets,
      workOrder: mdiTicket,
      financial: mdiCashMultiple,
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#0074d9',
        success: '#2ecc40',
        warning: '#ff851b',
        error: '#ff4136',
        secondary: '#ff4136',
        accent: '#001f3f',
        info: '#39CCCC',
      },
    },
  },
})
